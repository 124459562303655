<template>
  <div class="main mobile:overflow-hidden">
    <!--     Header -->
    <div
      id="header-wrapper"
      class="w-full h-auto laptop:h-64 relative overflow-hidden"
    >
      <div
        class="laptop:m-auto px-10 mobile:m-4 mobile:p-6 tablet:m-6 desktop:w-[900px]"
      >
        <div class="text-white font-bold">
          <div class="flex items-end space-x-2">
            <MBCLogo width="120px" color="white" />
            <div class="mobile:text-[1rem] text-[1.688rem] mobile:pb-1">
              통합제보
            </div>
          </div>
          <div
            class="flex flex-col laptop:flex-row space-x-0.5 tablet:items-start laptop:items-center mobile:text-[1rem] tablet:text-[1rem] laptop:text-[1.875rem]"
          >
            <MBCLogo width="90px" color="white" class="flex-none" />
            <div class="inline-block">
              뉴스는 24시간 여러분의 제보를 기다립니다.
            </div>
          </div>
          <div class="mt-2 bg-gradient-to-r from-gray-600 h-px"></div>
          <div
            class="space-y-0.5 mobile:text-xs tablet:text-xs laptop:text-sm pt-3.5 text-gray-400 mt-3 mobile:p-0 m-0"
          >
            <div>
              제보해주신 분의 신원은 철저히 보호되며, 제공하신 개인 정보는
              취재를 위해서만 사용됩니다.
            </div>
            <div>
              사건사고, 부정부패, 내부고발, 미담 등 관련 자료나 영상도 함께
              보내주세요.
            </div>
          </div>
        </div>
      </div>
      <img :src="setBackgroundImg" class="absolute bottom-2 left-32" />
    </div>

    <!-- Content -->
    <div
      id="content-wrapper"
      class="border-x border-gray-300 text-lg mobile:text-base"
    >
      <div
        class="flex flex-col desktop:mx-auto gap-y-2 p-10 mobile:p-6 mobile:items-center mobile:m-0"
      >
        <div class="w-full space-y-3">
          <!-- 이름 -->
          <div
            class="flex mobile:flex-col items-center mobile:gap-y-3 tablet:gap-x-3"
          >
            <div class="mobile:self-start w-24 font-bold">
              이름 <span class="text-red-500 text-sm">*</span>
            </div>
            <input
              ref="nameElement"
              v-model="name"
              class="mobile:w-full laptop:w-80 border px-3 py-2.5 rounded-md"
            />
          </div>
          <!-- 전화번호 -->
          <div
            class="flex mobile:flex-col items-center mobile:gap-y-3 tablet:gap-x-3"
          >
            <div class="mobile:self-start w-24 font-bold">
              전화번호 <span class="text-red-500 text-sm">*</span>
            </div>
            <div class="mobile:w-full flex justify-between items-center">
              <input
                ref="phoneNumberElement"
                v-model="phoneNumber1"
                maxlength="3"
                class="mobile:w-[31%] w-20 border px-3 py-2.5 rounded-md"
                @input="onCheckPhoneNumValidate($event)"
                @blur="onCheckPhoneNumValidate($event)"
              />
              -
              <input
                v-model="phoneNumber2"
                maxlength="4"
                class="mobile:w-[31%] w-20 border px-3 py-2.5 rounded-md"
                @input="onCheckPhoneNumValidate($event)"
                @blur="onCheckPhoneNumValidate($event)"
              />
              -
              <input
                v-model="phoneNumber3"
                maxlength="4"
                class="mobile:w-[31%] w-20 border px-3 py-2.5 rounded-md"
                @input="onCheckPhoneNumValidate($event)"
                @blur="onCheckPhoneNumValidate($event)"
              />
            </div>
          </div>
          <!-- 이메일 -->
          <div
            class="flex mobile:flex-col items-center mobile:gap-y-3 tablet:gap-x-3 border-b pb-12"
          >
            <div class="mobile:self-start w-24 font-bold">이메일</div>
            <input
              ref="emailElement"
              v-model="email"
              class="mobile:w-full tablet:w-80 border px-3 py-2.5 rounded-md"
              @blur="onCheckEmailValidate($event)"
            />
          </div>
          <!-- 지역 -->
          <div
            class="flex mobile:flex-col items-center mobile:gap-y-3 tablet:gap-x-3"
          >
            <div class="mobile:self-start w-24 font-bold">
              지역 <span class="text-red-500 text-sm">*</span>
            </div>
            <div
              class="flex flex-col justify-center mobile:items-center py-2.5"
            >
              <!-- 전체 체크박스 -->
              <div class="flex items-center mobile:self-start">
                <input
                  ref="localsElement"
                  v-model="locals"
                  type="checkbox"
                  name="locals"
                  value="all"
                  id="all"
                  class="pl-1 focus:ring focus:border-[#80BDFF] focus:ring-[#BADAFB] focus:outline-none"
                  :class="
                    locals.some(item => item === 'all')
                      ? 'bg-[#0069d9]'
                      : 'bg-[#dee2e6]'
                  "
                  :checked="locals.some(item => item === 'all')"
                  @input="onClicked('all', $event)"
                />
                <label for="all" class="ml-1">
                  <span class="text-lg mobile:text-base">전체 &nbsp;</span>
                </label>
              </div>

              <!-- 지역사 체크박스들 -->
              <div
                class="grid mobile:grid-cols-2 tablet:grid-cols-2 laptop:grid-cols-4 gap-3 mobile:gap-2 pt-3 laptop:space-y-1"
              >
                <div
                  v-for="local in localList"
                  :key="local.companyId"
                  class="flex items-center"
                >
                  <input
                    v-model="locals"
                    type="checkbox"
                    name="locals"
                    :value="local.companyId"
                    :id="local.companyId"
                    :checked="local.checked"
                    class="focus:ring focus:border-[#80BDFF] focus:ring-[#BADAFB] focus:outline-none flex-none"
                    :class="local.checked ? 'bg-[#0069d9]' : 'bg-[#dee2e6]'"
                    @input="onClicked(local, $event)"
                  />
                  <label :for="local.companyId" class="pl-1">
                    <div class="flex items-center space-x-0.5 justify-between">
                      <MBCLogo
                        v-if="local.logo === 'front'"
                        width="49px"
                        height="28px"
                        class="flex-none"
                      />
                      <div class="flex-none">{{ local.name }}</div>
                      <MBCLogo
                        v-if="local.logo === 'back'"
                        width="49px"
                        height="28px"
                        class="flex-none"
                      />
                      <a
                        v-if="local.kakaoLink"
                        :href="local.kakaoLink"
                        target="_blank"
                        class="flex-none"
                      >
                        <img
                          src="@/assets/icons/kakaotalk_icon.png"
                          :alt="local.name"
                          width="20"
                          height="20"
                        />
                      </a>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- 제목 -->
          <div
            class="flex mobile:flex-col items-center mobile:gap-y-3 tablet:gap-x-3"
          >
            <div class="mobile:self-start w-24 font-bold">
              제목 <span class="text-red-500 text-sm">*</span>
            </div>
            <input
              ref="titleElement"
              v-model="title"
              class="mobile:w-full tablet:w-80 border px-3 py-2.5 rounded-md flex-1"
            />
          </div>
          <!-- 내용 -->
          <div
            class="flex mobile:flex-col items-center mobile:gap-y-3 tablet:gap-x-3"
          >
            <div class="mobile:self-start w-24 font-bold">
              내용 <span class="text-red-500 text-sm">*</span>
            </div>
            <textarea
              ref="contentElement"
              v-model="content"
              class="mobile:w-full laptop:w-80 border px-3 py-2.5 rounded-md flex-1"
              :rows="10"
            />
          </div>
          <!-- 첨부파일 -->
          <div class="flex items-center mobile:gap-y-3 tablet:gap-x-3">
            <div class="mobile:hidden w-24 font-bold">첨부파일</div>
            <div class="space-y-0.5 flex-1">
              <div class="dropzone border border-gray-200 mobile:font-semibold">
                <div id="dropzone-preview-list">
                  <!-- This is used as the file preview template -->
                  <div class="mb-1.5 space-y-1">
                    <div
                      class="bg-gray-100 rounded-md text-xs flex items-center justify-between p-1.5"
                    >
                      <div class="flex space-x-2 items-center">
                        <LinkIcon class="h-4 w-4" />
                        <span class="font-semibold" data-dz-name="data-dz-name"
                          >&nbsp;</span
                        >
                      </div>
                      <button
                        data-dz-remove="data-dz-remove"
                        class="cursor-pointer"
                      >
                        <XMarkIcon class="h-4 w-4" />
                      </button>
                    </div>
                    <div
                      class="dz-error-message text-error text-red-500 text-sm pt-1.5 font-bold"
                    >
                      <span data-dz-errormessage></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-red-500 font-bold text-xs">
                <div>
                  * 여러 파일인 경우는 압축하여 1개의 파일로 등록하시기
                  바랍니다.
                </div>
                <div>* 파일은 최대 200MB까지 업로드 가능합니다.</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 정보제공 동의 영역 -->
        <div>
          <div
            class="border border-gray-300 px-5 py-4 text-sm mobile:text-xs rounded-md bg-[#D6D8D9] text-gray-700"
          >
            <div class="font-bold text-base mobile:text-sm">
              개인정보 수집 동의
            </div>
            <div class="pt-5 pb-0.5">
              1. 개인정보의 수집·이용 항목 : 수집한 개인정보를 제보 내용에 관한
              확인 및 처리 등의 업무를 위해 수집, 이용하고 있습니다.
            </div>
            <div class="pb-0.5">
              2. 수집하는 개인정보 항목 : 이름, 전화번호, 이메일 등 입력항목
            </div>
            <div>
              3. 개인정보의 보유 및 이용기간 : 개인정보의 수집 목적 또는
              제공받은 목적이 달성된 후에는 즉시 파기하는 것을 원칙으로 합니다.
            </div>
            <div class="pt-5">
              보내주신 정보들은 방송 및 온라인 매체 등 모든 형태의 뉴스 제작에
              활용되며, 추가 취재 등을 목적으로 연락처를 사용할 수 있습니다.
            </div>
          </div>
          <div class="py-5 font-bold flex items-center">
            <input
              ref="agreedElement"
              type="checkbox"
              name="confirmCheck"
              value="confirmCheck"
              id="confirmCheck"
              :checked="isAgreed"
              class="custom-button focus:ring focus:border-[#80BDFF] focus:ring-[#BADAFB] focus:outline-none"
              :class="isAgreed ? 'bg-[#0069d9]' : 'bg-[#dee2e6]'"
              @input="onClickedConfirm"
            />
            <label for="confirmCheck" class="pl-1"
              >개인정보 취급방침에 동의합니다.</label
            >
          </div>
        </div>
        <!-- 버튼들 -->
        <div class="flex justify-center gap-x-4 pt-14 pb-8 w-full">
          <button
            class="px-2 py-3 w-40 border rounded-md font-bold text-lg mobile:text-base bg-gray-400 text-white hover:bg-gray-500 duration-150"
            @click="onRefresh"
          >
            취소하기
          </button>
          <button
            class="px-2 py-3 w-40 border rounded-md font-bold text-lg mobile:text-base bg-[#363D85] text-white hover:bg-[#0069d9] duration-150"
            @click="onSubmit"
          >
            제보하기
          </button>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div
      id="footer-wrapper"
      class="w-full py-5 mt-5 laptop:px-10 desktop:px-20 font-bold mobile:flex-col tablet:flex-col laptop:flex mobile:items-center tablet:items-center laptop:items-start"
    >
      <div
        class="w-[90%] mobile:p-8 tablet:py-10 flex flex-wrap laptop:justify-start mobile:justify-center tablet:justify-center gap-2 text-gray-500 pb-16 text-xs pt-2"
      >
        <div
          v-for="(local, index) in localList"
          :key="local.value"
          class="flex items-center space-x-1"
        >
          <MBCLogo
            v-if="local.logo === 'front'"
            width="37px"
            height="23px"
            color="#6b7280"
          />
          <div>{{ local.name }}</div>
          <MBCLogo
            v-if="local.logo === 'back'"
            width="37px"
            height="23px"
            color="#6b7280"
          />
          <div
            v-if="index !== localList.length - 1"
            class="font-bold border-r-2 border-gray-500"
          >
            <span class="invisible">|</span>
          </div>
        </div>
      </div>
      <div
        class="w-full py-3 flex flex-col mobile:items-center tablet:items-center laptop:items-start space-y-4"
      >
        <a
          v-for="local in localList"
          :key="local.value"
          style="font-size: 80%; font-weight: 400"
          class="flex flex-col laptop:flex-row text-gray-500 cursor-pointer items-center justify-center max-w-max"
          :href="local.url"
          :target="true"
        >
          <div
            class="flex flex-none items-center space-x-1 mr-2 w-24 justify-center laptop:justify-start"
          >
            <MBCLogo
              v-if="local.logo === 'front'"
              width="37px"
              height="23px"
              color="#6b7280"
            />
            <div class="font-bold">{{ local.name }}</div>
            <MBCLogo
              v-if="local.logo === 'back'"
              width="37px"
              height="23px"
              color="#6b7280"
            />
          </div>
          <div>
            <div
              v-for="(item, index) in local.locals"
              :key="item.address"
              class="flex flex-col laptop:flex-row items-center laptop:items-start laptop:space-x-3"
            >
              <div>{{ item.address }}</div>
              <div v-if="index === 0" class="font-black">
                {{ local.url }}
              </div>
              <div class="font-black px-2 laptop:px-0">{{ item.tel }}</div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import axios from 'axios';
import Dropzone from 'dropzone';
import { LinkIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import 'dropzone/dist/dropzone.css';
import MBCLogo from '@/assets/icons/MBCLogo.vue';
import { cloneDeep } from 'lodash';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'ContactUs',
  components: {
    XMarkIcon,
    LinkIcon,
    MBCLogo,
  },
  emit: ['close'],

  setup() {
    // 브라우저 타이틀 setting
    document.title = 'MBC 통합제보';
    const route = useRoute();

    const baseURL = process.env.VUE_APP_ZODIAC_API_BASE_URL;
    const name = ref(null);
    const nameElement = ref(null);
    const phoneNumber1 = ref(null);
    const phoneNumber2 = ref(null);
    const phoneNumber3 = ref(null);
    const phoneNumberElement = ref(null);
    const email = ref(null);
    const emailElement = ref(null);
    const title = ref(null);
    const titleElement = ref(null);
    const content = ref(null);
    const contentElement = ref(null);
    const isAgreed = ref(false);
    const agreedElement = ref(null);
    const validations = ref({
      name: {
        error: '이름을 입력하세요.',
      },
      phoneNumber: {
        error: '전화번호를 입력하세요.',
      },
      email: {
        isSomethingWrong: false,
        error: '잘못된 이메일 형식입니다. 다시 입력해주세요.',
      },
      locals: {
        error: '제보할 지역을 선택하세요.',
      },
      title: {
        error: '제목을 입력하세요.',
      },
      content: {
        error: '내용을 입력하세요.',
      },
      agreed: {
        error: '개인정보 처리방침에 동의하셔야 합니다.',
      },
    });

    let fileUpload = null;
    const files = [];
    let fileId = 0;
    let errorMsg = null;

    // 선택된 지역사 ID값 목록
    const locals = ref([]);
    const localsElement = ref(null);
    // 지역의 부가정보가 들어있는 리스트 (companyId가 바뀌면 내용을 수정해야함...)
    const localInfoList = [
      {
        companyId: 2,
        url: 'https://busanmbc.co.kr',
        logo: 'back',
        locals: [
          {
            address: '부산광역시 수영구 감포로 8번길 69',
            tel: '051-760-1000',
          },
        ],
      },
      {
        companyId: 3,
        url: 'https://dgmbc.com',
        logo: 'back',
        locals: [
          {
            address: '대구광역시 수성구 욱수길 56 대구문화방송',
            tel: '053-740-9500',
          },
        ],
      },
      {
        companyId: 4,
        url: 'https://kjmbc.co.kr',
        logo: 'back',
        locals: [
          {
            address: '광주광역시 남구 월산로 116번길 17(월산동, 광주문화방송)',
            tel: '062-360-2315, 2580',
          },
        ],
      },
      {
        companyId: 5,
        url: 'https://tjmbc.co.kr',
        logo: 'back',
        locals: [
          { address: '대전광역시 유성구 엑스포로 161', tel: '042-330-3000' },
        ],
      },
      {
        companyId: 6,
        url: 'https://www.jmbc.co.kr',
        logo: 'back',
        locals: [
          {
            address: '전라북도 전주시 완산구 선너머1길 50',
            tel: '063-220-8000 (전화제보: 063-220-8103)',
          },
        ],
      },
      {
        companyId: 8,
        url: 'https://www.usmbc.co.kr',
        logo: 'back',
        locals: [
          {
            address: '울산광역시 중구 서원3길 65',
            tel: '052-290-1114',
          },
        ],
      },
      {
        companyId: 11,
        url: 'https://chmbc.co.kr',
        logo: 'back',
        locals: [
          {
            address: '춘천시 수변공원길 54',
            tel: '033-259-1215',
          },
        ],
      },
      {
        companyId: 12,
        url: 'https://ysmbc.co.kr',
        logo: 'back',
        locals: [
          {
            address: '전라남도 여수시 문수로 135',
            tel: '061-650-3333',
          },
        ],
      },
      {
        companyId: 14,
        url: 'https://jejumbc.com',
        logo: 'back',
        locals: [
          {
            address: '제주특별자치도 제주시 문연로 35 (연동)',
            tel: '064-740-2000 (뉴스제보: 064-740-2000)',
          },
        ],
      },
      {
        companyId: 15,
        url: 'https://mpmbc.co.kr',
        logo: 'back',
        locals: [
          {
            address: '전라남도 목포시 영산로 101 (명륜동 5-1)',
            tel: '061-270-9000~3',
          },
        ],
      },
      {
        companyId: 16,
        url: 'http://www.wjmbc.co.kr',
        logo: 'back',
        locals: [
          {
            address: '원주시 학성길 67 (학성동)',
            tel: '033-741-8114',
          },
        ],
      },
      {
        companyId: 19,
        url: 'http://www.phmbc.co.kr',
        logo: 'back',
        locals: [
          {
            address: '경상북도 포항시 남구 새천년대로 421 (대잠동)',
            tel: '054-289-0114 (기사제보: 054-289-0361, 동영상제보: 010-3301-7900)',
          },
        ],
      },
      {
        companyId: 20,
        url: 'https://andongmbc.co.kr',
        logo: 'back',
        locals: [
          {
            address: '경북 안동시 단원로 20 (구주소: 태화동 709-1)',
            tel: '054-851-7114 (제보전화: 054-853-1600)',
          },
        ],
      },
      {
        companyId: 23,
        url: 'https://mbcgn.kr',
        logo: 'front',
        locals: [
          {
            name: '충주',
            address: '충청북도 충주시 중원대로 3250',
            tel: '043-841-8114 (뉴스제보: 043-841-8333)',
          },
          {
            name: '청주',
            address: '충청북도 청주시 흥덕구 2순환로 1322',
            tel: '043-229-7114 (뉴스제보: 043-229-7000)',
          },
        ],
      },
      {
        companyId: 21,
        url: 'https://mbcgn.kr',
        logo: 'front',
        locals: [
          {
            name: '진주본부',
            address: '경상남도 진주시 가호로 13',
            tel: '055-250-5000',
          },
          {
            name: '창원본부',
            address: '경남 창원시 마산회원구 양덕서9길 11-11',
            tel: '055-771-2112',
          },
        ],
      },
      {
        companyId: 22,
        url: 'https://www.mbceg.co.kr',
        logo: 'front',
        locals: [
          {
            name: '강릉',
            address: '강릉시 가작로 267',
            tel: '033-650-2114',
          },
          {
            name: '삼척',
            address: '삼척시 새천년도로 629-59',
            tel: '033-571-3114',
          },
        ],
      },
    ];
    // 지역사 리스트
    const localList = ref([]);

    const setBackgroundImg = computed(() => {
      return require('@/assets/img/bg_1.png');
    });

    // 체크박스 선택
    // 체크 된 아이템의 value가 v-model의 배열 안에 들어있어야 체크표시가 화면에 보인다.
    // value 값 없이 checked만 true일 경우 색깔만 표시된다.
    const onClicked = (item, event) => {
      if (item === 'all') {
        localList.value.forEach(item => {
          item.checked = event.target.checked;
        });

        if (event.target.checked) {
          locals.value = localList.value.map(item => {
            return item.companyId;
          });
          locals.value.push('all');
        } else {
          locals.value = [];
        }
      } else {
        // 전체 선택이 체크되어 있는 상태에서 지역사 체크박스를 해제했을 경우, 전체 선택 체크박스를 해제한다.
        if (
          locals.value.some(item => item === 'all') &&
          !event.target.checked
        ) {
          const index = locals.value.findIndex(item => item === 'all');
          locals.value.splice(index, 1);
        }

        const findVal = localList.value.find(
          checkbox => checkbox.companyId === item.companyId,
        );
        findVal.checked = event.target.checked;

        if (localList.value.every(item => item.checked)) {
          locals.value.push('all');
        }
      }
    };

    // 개인정보 방침 체크박스 토글
    const onClickedConfirm = event => {
      isAgreed.value = event.target.checked;
    };

    // 전화번호 숫자 입력 체크
    const onCheckPhoneNumValidate = event => {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    };

    // 이메일 형식 체크
    const onCheckEmailValidate = event => {
      if (!event.target.value) {
        validations.value.email.isSomethingWrong = false;
        return;
      }

      let regex = new RegExp(
        /^[a-zA-Z0-9+-\\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      );
      if (regex.test(event.target.value)) {
        validations.value.email.isSomethingWrong = false;
      } else {
        validations.value.email.isSomethingWrong = true;
      }
    };

    // 파일 업로드
    const onUploadFiles = async () => {
      try {
        // Content-Type 지정
        const headers = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        const params = {
          file: files[0],
        };

        await axios
          .post(`${baseURL}/v1/report/file`, params, headers)
          .then(response => {
            fileId = response.data.data.attachedFileId;
          });
      } catch (error) {
        console.log('파일 업로드에 실패했습니다.', error);
      }
    };

    // 제보하기
    const onSubmit = async () => {
      // 이름, 전화번호, 지역, 제목, 내용 빈값 체크 및 정보동의 체크 확인
      if (!name.value) {
        window.alert(validations.value.name.error);
        nameElement.value.focus();
        return;
      } else if (
        !(phoneNumber1.value && phoneNumber2.value && phoneNumber2.value)
      ) {
        window.alert(validations.value.phoneNumber.error);
        phoneNumberElement.value.focus();
        return;
      } else if (!locals.value.length) {
        window.alert(validations.value.locals.error);
        localsElement.value.focus();
        return;
      } else if (!title.value) {
        window.alert(validations.value.title.error);
        titleElement.value.focus();
        return;
      } else if (!content.value) {
        window.alert(validations.value.content.error);
        contentElement.value.focus();
        return;
      } else if (validations.value.email.isSomethingWrong) {
        window.alert(validations.value.email.error);
        emailElement.value.focus();
        return;
      } else if (!isAgreed.value) {
        window.alert(validations.value.agreed.error);
        agreedElement.value.focus();
        return;
      }

      const allIdx = locals.value.findIndex(local => local === 'all');
      // 전체 체크박스를 계속 유지시키기 위해서 복사해서 사용
      let localIds = cloneDeep(locals.value);
      if (allIdx > 0) {
        localIds.splice(allIdx, 1);
      }

      try {
        if (files.length === 1) {
          // 파일 사이즈가 크다면 저장 X
          if (errorMsg && errorMsg.includes('File is too big')) {
            window.alert('파일을 업로드 할 수 없습니다. 확인해주세요.');
            return;
          } else {
            await onUploadFiles();
          }
        } else if (files.length > 1) {
          window.alert('파일을 업로드 할 수 없습니다. 확인해주세요.');
          return;
        }

        axios
          .post(`${baseURL}/v1/report`, {
            attachedFileId: fileId,
            companyId: localIds,
            content: content.value,
            email: email.value,
            isAgreed: isAgreed.value ? 'Y' : 'N',
            name: name.value,
            phone: `${phoneNumber1.value}-${phoneNumber2.value}-${phoneNumber3.value}`,
            title: title.value,
          })
          .then(() => {
            window.alert('제보가 완료되었습니다.');
            onRefresh();
          });
      } catch (error) {
        console.log('error', error);
      }
    };

    // 취소하기
    const onRefresh = () => {
      // 페이지 새로고침
      window.location.reload();
      window.scrollTo(0, 0);
    };

    // 지역사 전체 가져오기
    const getLocals = async () => {
      try {
        await axios.get(`${baseURL}/v1/company`, { delYn: 'N' }).then(res => {
          const localsFormList = res.data.data.map(item => {
            return {
              name: item.name,
              companyId: item.companyId,
              kakaoLink: item.kakaoLink,
              code: item.code,
              checked: false,
            };
          });

          localInfoList.forEach(info => {
            localsFormList.forEach(local => {
              if (info.companyId === local.companyId) {
                localList.value.push({ ...info, ...local });
              }
            });
          });
        });
      } catch (error) {
        console.log('error', error);
      }
    };

    // 파일 업로드 라이브러리 초기화
    const initDropzone = () => {
      Dropzone.autoDiscover = false;
      fileUpload = new Dropzone('div.dropzone', {
        url: `${baseURL}/v1/report/file`, // 파일을 업로드할 서버 주소 url.
        method: 'post', // 기본 post로 request 감. put으로도 할수있음

        autoProcessQueue: false, // 자동으로 보내기. true : 파일 업로드 되자마자 서버로 요청, false : 서버에는 올라가지 않은 상태. 따로 this.processQueue() 호출시 전송
        clickable: true, // 클릭 가능 여부
        autoQueue: false, // 드래그 드랍 후 바로 서버로 전송
        createImageThumbnails: false, //파일 업로드 썸네일 생성

        thumbnailHeight: 120, // Upload icon size
        thumbnailWidth: 120, // Upload icon size

        maxFiles: 1, // 업로드 파일수
        maxFilesize: 200, // 최대업로드용량 : 200MB
        // paramName: 'image', // 서버에서 사용할 formdata 이름 설정 (default는 file)
        parallelUploads: 1, // 동시파일업로드 수(이걸 지정한 수 만큼 여러파일을 한번에 넘긴다.)
        uploadMultiple: false, // 다중업로드 기능
        timeout: 300000, //커넥션 타임아웃 설정 -> 데이터가 클 경우 꼭 넉넉히 설정해주자

        addRemoveLinks: false, // 업로드 후 파일 삭제버튼 표시 여부
        dictRemoveFile: '삭제', // 삭제버튼 표시 텍스트
        // acceptedFiles: ".jpeg,.jpg,.png,.gif,.JPEG,.JPG,.PNG,.GIF", // 이미지 파일 포맷만 허용
      });

      // 파일이 업로드되면 실행
      fileUpload
        .on('addedfile', function (file) {
          files.push(file);
        })
        .on('maxfilesexceeded', function (file) {
          fileUpload.removeAllFiles();
          fileUpload.addFile(file);
        })
        .on('removedfile', function (file) {
          const delIdx = files.findIndex(item => item.name === file.name);
          files.splice(delIdx, 1);
        })
        // 업로드 에러 처리
        .on('error', function (file, errorMessage) {
          errorMsg = errorMessage;
          const msgElement = file.previewElement
            .querySelector('.dz-error-message')
            .getElementsByTagName('span')[0];
          msgElement.classList.add('text-xs', 'font-bold');

          if (errorMessage.includes('File is too big')) {
            msgElement.innerText = '파일의 최대 크기는 200MB 입니다.';
          }
        });

      // 파일 업로드 영역 텍스트 변경
      const dropzoneBtn = document.querySelector('.dz-button');
      dropzoneBtn.style.fontSize = '0.875rem';
      dropzoneBtn.innerText =
        '클릭 또는 드래그하여 파일을 첨부 하시기 바랍니다.';

      // 커스텀한 업로드 영역 사용
      let dropzonePreviewNode = document.querySelector(
        '#dropzone-preview-list',
      );
      dropzonePreviewNode.id = '';
      let previewTemplate = dropzonePreviewNode.parentNode.innerHTML;
      dropzonePreviewNode.parentNode.removeChild(dropzonePreviewNode);
      fileUpload.options.previewTemplate = previewTemplate;
    };

    onMounted(async () => {
      initDropzone();
      await getLocals();
      if (Object.keys(route.query).length > 0) {
        const regions = route.query.q.split(',');

        localList.value.forEach(item => {
          if (regions.includes(item.code)) {
            item.checked = true;
            locals.value.push(item.companyId);
          }
        });
      }
    });

    return {
      name,
      nameElement,
      phoneNumber1,
      phoneNumber2,
      phoneNumber3,
      phoneNumberElement,
      email,
      emailElement,
      title,
      titleElement,
      content,
      contentElement,
      isAgreed,
      agreedElement,
      validations,
      locals,
      localsElement,
      localList,
      setBackgroundImg,
      onClicked,
      onClickedConfirm,
      onSubmit,
      onRefresh,
      onCheckPhoneNumValidate,
      onCheckEmailValidate,
    };
  },
});
</script>
<style scoped>
.main {
  display: flex;
  flex-direction: column;
}

#header-wrapper,
#content-wrapper,
#footer-wrapper {
  display: flex;
  flex-direction: column;
}

#header-wrapper {
  background: rgb(29, 41, 125);
  background: linear-gradient(
    155deg,
    rgba(29, 41, 125, 1) 0%,
    rgba(16, 20, 80, 1) 59%,
    rgba(53, 22, 103, 0.9752275910364145) 100%
  );
}
#footer-wrapper {
  background: rgb(8, 15, 62);
  background: linear-gradient(
    352deg,
    rgba(8, 15, 62, 1) 0%,
    rgba(32, 35, 70, 1) 59%,
    rgba(31, 31, 42, 0.9752275910364145) 100%
  );
}
input[type='checkbox'] {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 25%;
  appearance: none;
  cursor: pointer;
  transition: background-color 0.1s;
}
input[type='checkbox'] + label {
  display: inline-block;
  cursor: pointer;
}
input[type='checkbox']:checked {
  background-image: url('/src/assets/icons/CheckIcon.svg');
  color: white;
}
.dropzone {
  min-height: 15px;
  padding: 15px 10px;
}

.dropzone >>> .dz-message {
  text-align: center;
  margin: 0;
}
</style>
