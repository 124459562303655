<template>
  <svg
    viewBox="0 0 839 241"
    :fill="color"
    :width="width"
    :height="height"
    class="inline-block"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.1333 1.80003C35.7333 3.40003 22.5333 9.93336 16.4 15.4C13.4667 18.0667 9.46667 23.2667 7.46667 27C0 40.7334 0 39.5334 0 143.933V240.333H31.3333H62.6667V151V61.6667L87.0667 61.9334L111.333 62.3334L111.733 151.267L112 240.333H143.333H174.667V151V61.6667H198.667H222.667V151V240.333H254H285.467L285.067 139.933L284.667 39.6667L280.4 30.7334C273.2 15.5334 261.6 6.60003 243.733 2.33336C236.667 0.733362 220.133 0.333362 142.667 0.466695C91.7333 0.600028 47.3333 1.13336 44.1333 1.80003Z"
      :fill="color"
    />
    <path
      d="M322.667 120.333V240.467L421.733 240.067C518.933 239.667 520.8 239.533 531.067 236.733C548 231.933 558.267 226.333 568.133 216.467C580.533 204.067 583.2 196.867 583.2 176.333C583.2 163.533 582.667 159.8 580.133 153.267C572.4 133.533 548.4 118.333 521.067 115.667C516.533 115.267 510.133 114.6 507.067 114.2L501.333 113.533V146.867V180.333H444H386.667V120.333V60.3334H444H501.333V85V109.667H505.867C522.133 109.667 546.267 100.733 555.867 91.1334C564.667 82.3334 568.4 73.6667 569.067 60.2C570.533 31.2667 556.267 13 524.667 3.53336C517.333 1.40002 508.267 1.13336 419.733 0.733356L322.667 0.200022V120.333Z"
      :fill="color"
    />
    <path
      d="M645.733 2.20003C628.4 6.4667 613.867 18.3334 607.733 33.4C604.667 41 604.667 41.1334 604.267 115.667C604 168.2 604.267 192.733 605.333 198.333C609.467 219.267 623.2 233 645.6 238.333C652.533 239.933 669.6 240.333 746.4 240.333H838.667V210.333V180.333H754H669.333V120.333V60.3334H753.333H837.333V30.3334V0.333362L745.067 0.466695C676 0.466695 650.933 1.00003 645.733 2.20003Z"
      :fill="color"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MBCLogo',
  props: {
    width: {
      type: String,
      default: '50px',
    },
    height: {
      type: String,
      default: '50px',
    },
    color: {
      type: String,
      default: '#000000',
    },
  },

  setup() {
    return {};
  },
});
</script>
